<template>
  <v-container>
    <DisplayReleaseNotes />
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
export default {

  components: {
    DisplayReleaseNotes: () => import('@/views/pages/components/help/DisplayReleaseNotes')
  },

  created () {
    if (!this.$store.getters['auth/areReleaseNotesSeenLatest']()) {
      this.init()
    }
  },

  methods: {
    ...mapActions('auth', ['seeReleaseNotes']),
    init () {
      this.seeReleaseNotes({ userId: this.$store.getters['auth/getUserId']() })
    }
  }

}
</script>

<style lang="scss" scoped>

</style>
